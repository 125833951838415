.page {
  height: 100vh;
}

.modalContent {
  margin-top: 38px;
}

.tab {
  height: 100%;

  :global {
    .ant-tabs-content {
      height: 100%;

      > .ant-tabs-tabpane {
        height: 100%;
      }
    }
  }
}
